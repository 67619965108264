import { AppBar, Box, Stack, Typography } from "@mui/material";

import { useTheme } from '@mui/material/styles';

const SiteFooter = () => {
  const theme = useTheme();

  return (
    <AppBar position="static" variant='outlined'>
      <Box sx={{ m: 2 }}>
        <Stack direction='column' alignItems='center' justifyContent='center'>
          <Typography variant='overline'>© 2023 SWEETWARE TECHNOLOGIES, LLC</Typography>
          <Typography variant='overline'>ALL RIGHTS RESERVED</Typography>
        </Stack>
      </Box>
    </AppBar>
  );
}

export default SiteFooter;