// import React from 'react';
// import logo from './logo.svg';
// import './App.css';

import { Box } from "@mui/material";

import { ThemeProvider, createTheme } from '@mui/material/styles';

import MainPage from "./pages/MainPage";
import SiteHeader from "./components/SiteHeader";
import SiteFooter from "./components/SiteFooter";

import bgSite from './images/bg-site.jpg';
// import bgSite from './images/bg-site-0.jpg';
// import bgSite from './images/bg-site-1.jpg';

const theme = createTheme({
  palette: {
    mode: 'light',
    // mode: 'dark',
    primary: {
      // main: '#b53f41',
      // main: '#ad4a4b',
      main: '#a74d4e',
      // main: '#9a3a3a',
      // main: '#641a1a',
    },
    secondary: {
      // main: '##efebfb',
      main: '#eeebfb', 
      // main: '#fbebeb',     
    },
  },
  typography: {
    // fontFamily: 'Montserrat',
    fontFamily: 'Maven Pro',
    h1: {
      fontFamily: 'Jura',
    },
    h2: {
      fontFamily: 'Jura',
    },
    h3: {
      fontFamily: 'Jura',
    },
    h4: {
      fontFamily: 'Jura',
    },
    h5: {
      fontFamily: 'Jura',
    },
    h6: {
      fontFamily: 'Jura',
    },
    caption: {
      fontFamily: 'Jura',
    },
  },
});

function App() {
  return (
    <ThemeProvider theme={theme}>
      <Box
        height="100%"
        display="flex"
        justifyContent="center"
        flexDirection="column"
      >
        <SiteHeader />
        <div style={{ backgroundPosition: 'center', backgroundSize: 'cover', backgroundImage: `url(${bgSite})`, minHeight: '100vh', width: '100%' }}>
          <MainPage />
        </div>
        <SiteFooter />
      </Box>
    </ThemeProvider>
  );
}

export default App;
