import { useState } from "react";
import { AppBar, Box, Button, Grid, IconButton, Stack, Typography } from "@mui/material";
import EmailIcon from '@mui/icons-material/Email';

import IcecreamIcon from '@mui/icons-material/Icecream';

import { useTheme } from '@mui/material/styles';

import ContactDialog from "./ContactDialog";

const SiteHeader = () => {
  const theme = useTheme();

  const [openContact, setOpenContact] = useState(false);

  const handleClickOpenContact = () => {
    setOpenContact(true);
  };

  const handleCloseContact = () => {
    setOpenContact(false);
  };

  return (
    <AppBar position="static" variant="outlined">
      <Box sx={{ m: 2 }}>
          <Stack direction='row' alignItems='center' justifyContent='space-between'>
            <Grid display={{ xs: 'none', md: 'block' }}>
              <Stack direction='row' spacing={1} alignItems='center' justifyContent='center'>
                <IcecreamIcon fontSize='large' sx={{ color: '#ffffff' }} />
                <Typography variant='h3'>Sweetware</Typography>
                <Typography variant='h5'>Technologies</Typography>
              </Stack>
            </Grid>
            <Grid display={{ xs: 'none', sm: 'block', md: 'none' }}>
              <Stack direction='row' spacing={1} alignItems='center' justifyContent='center'>
                <IcecreamIcon fontSize='small' sx={{ color: '#ffffff' }} />
                <Typography variant='h4'>Sweetware</Typography>
                <Typography variant='h6'>Technologies</Typography>
              </Stack>
            </Grid>
            <Grid display={{ xs: 'block', sm: 'none' }}>
              <Stack direction='row' spacing={1} alignItems='center' justifyContent='center'>
                <IcecreamIcon fontSize='small' sx={{ color: '#ffffff' }} />
                <Typography variant='h5'>Sweetware</Typography>
                <Typography variant='caption'>Technologies</Typography>
              </Stack>
            </Grid>
            <Grid display={{ xs: 'none', md: 'block' }}>
              <Stack direction='row' spacing={1} alignItems='center' justifyContent='center'>
                <Typography variant='overline'>Full Stack.</Typography>
                <Typography variant='overline'>Security.</Typography>
                <Typography variant='overline'>Accessibility.</Typography>
              </Stack>
            </Grid>
            <Grid display={{ xs: 'none', sm: 'block' }}>
              <Button sx={{ textTransform: 'none' }} color="secondary" onClick={handleClickOpenContact}>
                <Stack direction='row' spacing={1} alignItems='center' justifyContent='center'>
                  <EmailIcon />
                  <Typography variant='overline' fontWeight={1000}>Contact Us</Typography>
                </Stack>
              </Button>
            </Grid>
            <Grid display={{ xs: 'block', sm: 'none' }}>
              <IconButton color="secondary" onClick={handleClickOpenContact}>
                <EmailIcon />
              </IconButton>
            </Grid>
          </Stack>
          <Grid xs={12} display={{ xs: 'block', md: 'none' }}>
            <Stack direction='row' spacing={2} alignItems='center' justifyContent='left' sx={{ ml: 4 }}>
              <Typography variant='overline'>Full Stack.</Typography>
              <Typography variant='overline'>Security.</Typography>
              <Typography variant='overline'>Accessibility.</Typography>
            </Stack>
          </Grid>
      </Box>
      <ContactDialog
        open={openContact}
        onClose={handleCloseContact}
      />
    </AppBar>
  );
}

export default SiteHeader;